.home-container{
    a {
        cursor: pointer !important;
    }
    .top-bar-container{
        .top-bar{
            a{
                &.active{
                    color: red;
                }
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
    .topics{
        .home{
            // width: 135px !important;
        }
    }
    .container{
        .image-gallegy{
            // display: flex;
            // flex-wrap: wrap;
            // max-width: 800px;
            // margin: 0 auto;

            display: flex;
            overflow: hidden; /* Để ẩn các hình ảnh vượt ra khỏi khung */
            max-width: 800px;
            // margin: 0 auto;

            .thumbnail-container {
                display: flex; /* Đảm bảo các hình ảnh được xếp kín */
                animation: scrollAnimation 30s linear infinite; /* Thực hiện cuộn tự động */
            
                .thumbnail{
                    // margin: 10px;
                    // cursor: pointer;
                    margin: 10px;
                    width: 350px; /* Độ rộng của mỗi hình ảnh */
                    height: 200px;
                }
                img {
                    width: 100%;
                    height: auto;
                }

                @keyframes scrollAnimation {
                    0% {
                      transform: translateX(0); /* Bắt đầu từ vị trí ban đầu */
                    }
                    100% {
                      transform: translateX(-100%); /* Di chuyển sang phải */
                    }
                }

            }

            .preview{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 999;
            }
        }
    }
}






